.product_cart {
  border: 1px solid $plum;
  padding: 1rem 1rem 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  .product {
    &_image {
      width: 15%;
    }
    &_title {
      font-weight: bold;
      width: 50%;
      font-size: 1.6rem;
    }
    &_name {
      font-size: 1.2rem;
    }
    &_price {
      width: 10%;
      text-align: right;
      font-size: 1.4rem;
      line-height: 1;
    }
    &_add-remove-actions {
      width: 20%;
      span {
        border: 1px solid $gray;
        padding: 0.5rem 0.8rem;
        margin: 0.5rem;
      }
      button {
        background: $white;
        border: 1px solid $gray;
        padding: 0.2rem 0.5rem;
        border-radius: 50%;
        cursor: pointer;
        font-weight: bold;
        outline: none;
      }
    }
  }
  .close_btn {
    position: absolute;
    right: 10px;
    top: 10px;
    border: 1px solid $plum;
    border-radius: 50%;
    background: $white;
    color: $plum;
    outline: none;
    padding: 0.2rem 0.2rem 0;
    cursor: pointer;
    .close {
      width: 0.8rem;
      height: 0.8rem;
      transform: rotate(45deg);
    }
  }
}
.price_details {
  text-align: right;
  font-size: 1.3rem;
  margin: 3rem 0 5rem;

  .price_label {
    border-bottom: 1px solid $plum;
    color: $plum;
    font-weight: bold;
    font-size: 1.5rem;
    text-align: left;
  }
}
