.productdetail {
  margin: 2rem 0 0;
  border: none;
  &_wrapper {
    display: flex;
  }
  &_image {
    width: 50%;
    img {
      width: 100%;
    }
    .carousel {
      .control-dots {
        position: relative;
        padding: 0;
        margin: 2rem 0 0;
        .dot {
          border: 1px solid $gray;
          box-shadow: none;
          width: 22px;
          height: 22px;
          outline: none;
        }
      }
      .thumbs-wrapper,
      .carousel-status,
      .control-arrow {
        display: none;
      }
    }
  }
  &_inner {
    border: 1px solid $light-gray;
    padding: 2rem 2.5em 1.3rem;
    margin: 0.2rem 0 0;
  }
  &_oval {
    display: flex;
    justify-content: center;
    margin: 2rem 0 1rem;
    span {
      border-radius: 50%;
      border: 1px solid $gray;
      padding: 10px;
      &:nth-child(2),
      &:nth-child(3) {
        margin-left: 1.5rem;
      }
    }
  }
  &_content {
    width: 50%;
    padding-left: 4rem;
  }
  &_bottle {
    padding: 0.5rem 1rem;
    width: 10rem;
    font-size: 1.3rem;
    * {
      background-color: $gray-light-two !important;
      border-radius: 0 !important;
      justify-content: center;
    }
  }
  &_quantity {
    padding: 0;
    margin-right: 1rem;
    width: 6rem;
    font-size: 1.3rem;
    * {
      background-color: $gray-light-two !important;
      border-radius: 0 !important;
      justify-content: center;
    }
  }
  &_button {
    padding: 0;
    margin-bottom: 2rem;
    border: none;
    background: none;
    outline: none;
  }
  .downarrow {
    padding: 0 0.8rem;
    position: relative;
    top: 12px;
    left: 3px;
  }
  .cta {
    border: 1px solid $plum;
    border-radius: 6px;
    outline: none;
    font-size: 1.3rem;
    font-weight: bold;
    cursor: pointer;
  }
  .wishlist {
    color: $plum;
    margin-top: 1.2rem;
    background-color: transparent;
    padding: 0.5rem 2rem 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .basket {
    background-color: $plum;
    color: $white;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .love {
    float: left;
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
  }
  .basket_button {
    background-color: $white;
    display: inline-block;
    margin-left: 1rem;
    padding: 0 4px;
    border-radius: 4px;
  }
  .plus {
    position: relative;
    top: 3px;
  }
  &_name {
    margin: 0rem 0 2rem;
    color: $dark-plum;
    font-size: 1.8rem;
    line-height: 1;
  }
  &_title {
    color: $dark-plum;
    font-size: 1.5rem;
    line-height: 1;
  }
  &_price {
    color: $light-plum;
    font-size: 2rem;
    margin: 0 0 1rem;
    font-weight: bold;
    sup {
      font-size: 1.5rem;
    }
  }

  &_offers {
    display: flex;
    background-color: $gloden;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 0.5rem;
    font-weight: bold;
    width: 35%;

    &_save {
      font-size: 0.8rem;
      color: $plum;
      margin-right: 0.5rem;
    }
    &_save_was {
      font-size: 0.8rem;
      color: $black;
      text-decoration: line-through;
    }
  }
  &_rating {
    width: 12rem;
    padding: 0.5rem 0;
  }
  &_offer {
    padding: 0 1rem;
    color: $plum;
    font-size: 1rem;
  }
  &_moreinfo {
    margin: 1rem 0 0;
    color: $dark-plum;
    font-size: 1.3rem;
    line-height: 1.2;
  }
  &_highlights {
    padding-right: 1rem;

    .small_title {
      color: $plum;
      font-weight: bold;
      font-size: 1.5rem;
    }
    ul {
      padding: 0;
      width: 100%;
      display: inline-block;
      margin: 0;

      li {
        margin: 0 0 0.5rem;
        list-style-type: none;
        list-style-position: inside;
        font-size: 0.9rem;
        display: flex;
        width: 50%;
        color: $light-plum;
        float: left;
        span {
          font-size: 1rem;
          color: $dark-plum;
          font-weight: 600;
          width: 50%;
        }
      }
    }
  }
  &_highlights {
    width: 70%;
  }
}
