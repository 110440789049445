$font-family: 'Gotham A', Helvetica, sans-serif;

body {
  font-size: $base-font-size; /* This overrides the browsers default font size */
  line-height: 1.5; /* If you change the font-size make sure you change the line-height value as well - the usual ratio is around 1.5 (font-size x 1.5 = line-height) */
  font-family: $font-family;
}

button,
input,
select,
textarea {
  font-family: $font-family;
}
select {
  font-family: $font-family;
  option {
    font-family: $font-family;
  }
}

body a {
  text-decoration: none;
} /* this removes the underline from all links */

body a:link {
  -webkit-tap-highlight-color: $light-plum;
}

h1,
h2,
h3,
h4 {
  font-weight: bold;
  line-height: 28px;
  margin-bottom: 16px;
}

p {
  margin-bottom: 16px;
}
