.product {
  padding: 1rem 1.5rem;
  border: solid 1px #eee;
  margin: 0 1rem 1rem;
  text-align: left;

  cursor: pointer;
  &_img_container {
    text-align: center;
    position: relative;
  }
  &_ribbon {
    position: absolute;
    right: 0;
    top: 0;
    color: $white;
    background: $plum;
    text-transform: uppercase;
    padding: 0.2rem 0.5rem;
    font-size: 0.7rem;
    line-height: 1;
    &:empty {
      display: none;
    }
  }
  &_name {
    color: $plum;
    font-size: $base-font-size;
    line-height: 1.5;
    font-weight: normal;
    margin: 0.5rem 0;
  }
  &_price {
    color: $light-plum;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: bold;
    margin: 0.5rem 0;
  }
  &_brand {
    font-size: 0.8rem;
    margin: 0.5rem 0;
    color: $dark-plum;
  }
  .offers {
    display: flex;
    background-color: $gloden;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 0.5rem;
    font-weight: bold;

    &_save {
      font-size: 0.8rem;
      color: $plum;
      margin-right: 0.5rem;
    }
    &_save_was {
      font-size: 0.8rem;
      color: $black;
      text-decoration: line-through;
    }
  }
  .cta_btn {
    color: $plum;
    border: 1px solid $plum;
    border-radius: 5px;
    background-color: transparent;
    padding: 0.5rem 1rem;
    display: block;
    width: 100%;
    outline: none;
    margin: 0 0 1rem;
    cursor: pointer;

    &:hover {
      color: $white;
      background-color: $plum;
    }
  }
}
.sort_buttons {
  width: 20%;
  margin-top: 1rem;
}
