.footer {
  border-top: solid 1px rgba(208, 39, 58, 0.59);
  padding: 1.5rem 0;
  &_list {
    display: flex;
    width: 100%;
  }
  &_items {
    width: 33%;
    & ul {
      margin: 0;
      padding: 0;
    }
    & li {
      line-height: 1.8;
      list-style: none;
      font-size: 0.9rem;
      color: #4c0f17;
    }
  }
  h4 {
    color: #4c0f17;
    margin: 0.7rem 0;
  }
}
