body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import './mixins';
@import './modal';
@import './overlay';
@import './reset';
@import './layout';
@import './vars';
@import './header';
@import './footer';
@import './product';
@import './productpage';
@import './postcode';
@import './_typography';
@import './_productdetails';
@import './_cart';
@import './rating';
@import './_select';
