@mixin absolute-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@mixin fixed-cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
