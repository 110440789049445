.product_list {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem -1rem;
  max-width: 100rem;
}
.filters {
  button {
    margin: 1rem 0.5rem;
    padding: 0.5rem 1rem;
    color: $plum;
    border: 1px solid $plum;
    border-radius: 5px;
    background: transparent;
    outline: none;
  }
}
