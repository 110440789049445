$light-plum: #4c0f17;
$plum: #d0273a;
$white: #fff;
$black: #000000;
$light-gray: #eee;
$gray: #979797;
$dark-plum: #590202;
$gloden: #f2b705;
$grey-light: #b9b9b9;
$base-font-size: 16px;
$gray-light-one: #c5c5c5;
$gray-light-two: #f4f4f4;
