.overlay {
  @include fixed-cover;
  background: rgba(0, 0, 0, 0.3);

  &-container {
    background: #fff;
    max-width: 350px;
    margin: 15% auto;
  }
  &-header {
    text-align: center;
    padding: 30px;
  }
  &-body {
    padding: 20px;
  }
}
