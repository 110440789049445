.header {
  border-bottom: solid 1px rgba(208, 39, 58, 0.59);
  padding: 1.5rem 0;
}
.navigation {
  display: flex;
  justify-content: space-between;
  & .logo {
    margin-top: 0.2em;
  }
  &_buttons {
    display: flex;

    & .header_icons {
      padding-left: 1.5rem;
    }
    & .search {
      background-color: transparent;
      background-repeat: no-repeat;
      background-image: url("./../../src/components/Header/svg/search.svg");
      background-position: right 0.7em top 50%, 0 0;
      background-size: 1.4em auto, 100%;
      border: 2px solid $plum;
      border-radius: 5px;
      font-size: 1.2rem;
      width: 10em;
      padding: 0.4rem 1em;
      transition: 0.3s;
      cursor: pointer;
    }
    .search:focus {
      outline: none;
      border: 2px solid $light-plum;
      width: 20em;
    }
    & .cart,
    & .love,
    .store-pin {
      outline: none;
      border: 0;
      height: 3em;
      width: 3em;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }
    .cart {
      position: relative;
      display: inline-block;
      .quantity {
        background-color: $plum;
        padding: 0.3rem 0.5rem;
        color: $white;
        border-radius: 50%;
        line-height: 1;
        font-size: 0.9rem;
        position: absolute;
        right: -5px;
        top: -7px;
      }
    }
  }

  &_wrapper {
    display: flex;
  }
  &_side-bar {
    height: 100vh;
    background: rgb(228, 221, 221);
    position: fixed;
    top: 0;
    left: -100%;
    width: 25%;
    transition: transform 0.3s ease-out;
    display: none;
    z-index: 1;
    .active & {
      display: block;
      left: 0;
    }
  }
  &_list {
    padding: 0;
    margin-top: 10vh;
    & li {
      display: block;
      font-size: 2.5rem;
      line-height: 2;
      text-align: center;
    }
    & a {
      text-decoration: none;
      overflow: hidden;
      color: #4c0f17;
      &:hover {
        color: #d0273a;
      }
    }
  }
  &_menu {
    display: block;
    margin-right: 2rem;
    width: 30px;
    height: 18px;
    position: relative;
    top: 0.9em;
    left: 0;
    z-index: 10;
    background: none;
    border: none;
    outline: none;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #4c0f17;
      opacity: 1;
      left: 0;
      transform: rotate(0);
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 50%;
        margin-top: -(2px/2.5);
      }
      &:nth-child(4) {
        bottom: 0;
      }
      .active &:nth-child(1),
      .active &:nth-child(4) {
        width: 0%;
        left: 50%;
      }
      .active &:nth-child(2) {
        transform: rotate(45deg);
      }
      .active &:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }
}
