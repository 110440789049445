.postcode_searchbar {
  text-align: center;
  &.hide {
    display: none;
  }
  input[type="text"] {
    width: 90%;
    padding: 10px 5px;
    font-size: 12px;
  }
}
.select-post-code {
  &.active {
    color: red;
  }
}

.postcode_list {
  overflow-y: auto;
  max-height: 300px;
  li {
    list-style: none;
    border-bottom: 1px solid lightgray;
    cursor: pointer;
    padding: 12px 24px;
  }
}

.filtered_store {
  li {
    list-style: none;
    cursor: pointer;
    padding: 10px 20px;
    border-bottom: 1px solid lightgray;
  }
  &_options {
    padding: 10px;
    &_right {
      background-color: #d0273a;
      color: white;
    }
    &_left {
      background-color: white;
      color: #d0273a;
      border: 1px solid #d0273a;
      margin-right: 20px;
    }
    button {
      padding: 8px 10px;
    }
  }
}

.selected_store {
  &_conatiner {
    display: flex;
    justify-content: space-between;
  }
  &_change {
    background-color: #d0273a;
    color: white;
    padding: 8px 10px;
    margin-left: 20px;
    border: none;
  }
}

.unavailable_store_location {
  text-align: center;
  &.active {
    display: none;
  }
}
